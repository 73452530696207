/*
 * Filter Anything v1.0.0 ~ Sun Siyam Experiences Edition
 * https://github.com/80DAYS/Filter-Anything
 */
 $(document).ready( function () {
 	$('[data-experiences-filter]').each( function () {
 		var $_filterElement = $(this);
 		var $_filterItems = $_filterElement.find( '.' + $_filterElement.data('experiences-filter-class') );
 		var $_resortFilterItems = $_filterElement.find( '.' + $_filterElement.data('experiences-resort-filter-class') );


		// Step 1: assemble a full list of each 
		var filterCategories = '';
		$_filterItems.each(function () {
			if (typeof $(this).data('experiences-filter-categories') !== 'undefined') {
				filterCategories += $(this).data('experiences-filter-categories') + '|';
			}
		});
		filterCategories = filterCategories.substring(0, filterCategories.length - 1); // remove trailing |
		filterCategories = filterCategories.split('|'); // make it an array
		filterCategories = unique(filterCategories); // remove duplicates

		//resort list
		var resortFilterCategories = '';
		$_resortFilterItems.each(function () {
			if (typeof $(this).data('experiences-resort-filter-categories') !== 'undefined') {
				resortFilterCategories += $(this).data('experiences-resort-filter-categories') + '|';
			}
		});
		resortFilterCategories = resortFilterCategories.substring(0, resortFilterCategories.length - 1); // remove trailing |
		resortFilterCategories = resortFilterCategories.split('|'); // make it an array
		resortFilterCategories = unique(resortFilterCategories); // remove duplicates
		
		
		// Step 2: create the filter buttons with an optional dropdown on mobile
		var hasfilterDropdown = false;
		var filterDropdown = '';
		var buttonClass = '';
		
		if (typeof $_filterElement.data('experiences-filter-controls-select-dropdown-mobile') !== 'undefined') {
			hasfilterDropdown = true;
			buttonClass = 'hide-for-small-only';
			filterDropdown = '<div class="experiences-filter-controls-dropdown show-for-small-only"><select autocomplete="off"><option value="all">All</option>';
		}
		
		var filterButtons = '<button class="button all on ' + buttonClass + '">all</button>';
		
		for (var category in filterCategories) {
			filterButtons += '<button class="button ' + buttonClass + '" data-experiences-filter-category="' + filterCategories[category] + '">' + filterCategories[category] + '</button>';
			
			if (hasfilterDropdown) {
				filterDropdown += '<option value="' + filterCategories[category] + '">' + filterCategories[category] + '</option>';
			}
		}

		//var filterApply = '<div class="hide-for-medium text-center"><button class="set-filters book-button"><span class="apply-filter">apply filter</span></button></div>';
		
		$_filterElement.find( '.' + $_filterElement.data('experiences-filter-controls') ).append(filterButtons);//.append(filterApply);
		
		if (hasfilterDropdown) {
			filterDropdown += '</select></div>';
			$_filterElement.find( '.' + $_filterElement.data('experiences-filter-controls') ).append(filterDropdown);
		}

		$_filterItems.fadeIn();



		// resort buttons
		var hasresortfilterDropdown = false;
		var resortFilterDropdown = '';
		var resortButtonClass = '';
		
		if (typeof $_filterElement.data('experiences-resort-filter-controls-select-dropdown-mobile') !== 'undefined') {
			hasresortfilterDropdown = true;
			resortButtonClass = 'hide-for-small-only';
			resortFilterDropdown = '<div class="experiences-resort-filter-controls-dropdown show-for-small-only"><select autocomplete="off"><option value="all">All</option>';
		}
		
		var resortFilterButtons = '<button class="button all on ' + resortButtonClass + '">all</button>';
		
		for (var category in resortFilterCategories) {
			resortFilterButtons += '<button class="button ' + resortButtonClass + '" data-experiences-resort-filter-category="' + resortFilterCategories[category] + '">' + resortFilterCategories[category] + '</button>';
			
			if (hasresortfilterDropdown) {
				resortFilterDropdown += '<option value="' + resortFilterCategories[category] + '">' + resortFilterCategories[category] + '</option>';
			}
		}

		//var filterApply = '<div class="hide-for-medium text-center"><button class="set-filters book-button"><span class="apply-filter">apply filter</span></button></div>';
		
		$_filterElement.find( '.' + $_filterElement.data('experiences-resort-filter-controls') ).append(resortFilterButtons);//.append(filterApply);
		
		if (hasresortfilterDropdown) {
			resortFilterDropdown += '</select></div>';
			$_filterElement.find( '.' + $_filterElement.data('experiences-resort-filter-controls') ).append(resortFilterDropdown);
		}

		$_resortFilterItems.fadeIn();


		
		
		// Step 4: set up the button events
		var $_filterButtons = $_filterElement.find( '.' + $_filterElement.data('experiences-filter-controls') ).find('button').add($_filterElement.find( '.' + $_filterElement.data('experiences-resort-filter-controls') ).find('button'));
		var filterCategoryType = new Array();
		var filterCategoryResort = new Array();
		var allFilters = $_filterItems.add($_resortFilterItems);

		$_filterButtons.on('click', function () {
			// Clear the "All" button
			if (!$(this).hasClass('all')) {
				$(this).siblings('.all').removeClass('on');
				if($(this).hasClass('on')){
					$(this).removeClass('on');
					filterCategoryType.splice(filterCategoryType.indexOf($(this).data('experiences-filter-category')), 1);
					filterCategoryResort.splice(filterCategoryResort.indexOf($(this).data('experiences-filter-category')), 1);
				}else{
					$(this).addClass('on');
					if ( $(this).parent().hasClass("experiences-type-content") ) {
						filterCategoryType.push($(this).data('experiences-filter-category'));
					} else {
						filterCategoryResort.push($(this).data('experiences-resort-filter-category'));
					}
				}
				if(filterCategoryType.length == 0){
					$('.filter-controls button').removeClass('on');
					$('.filter-controls button').filter('.all').addClass('on');
				}
				if(filterCategoryResort.length == 0){
					$('.resort-filter-controls button').removeClass('on');
					$('.resort-filter-controls button').filter('.all').addClass('on');
				}
			} else {
				// All button has been clicked, so show all buttons and return
				if ( $(this).parent().hasClass('filter-controls') ) { // Reset just the experience category filter
					$('.filter-controls button').removeClass('on');
					$('.filter-controls button').filter('.all').addClass('on');
					filterCategoryType = [];
				} else if ( $(this).parent().hasClass('resort-filter-controls') ) { // Reset just the resort filter
					$('.resort-filter-controls button').removeClass('on');
					$('.resort-filter-controls button').filter('.all').addClass('on');
					filterCategoryResort = [];
				} else { // Otherwise reset both filters
					$_filterButtons.removeClass('on');
					$_filterButtons.filter('.all').addClass('on');
					$_filterElement.find('.experiences-filter-controls-dropdown select').val('all');
					filterCategoryType = [];
					filterCategoryResort = [];
					$_filterItems.fadeOut();
					$_filterItems.fadeIn();
					return; //  only return if both filters are cleared, i.e. resetting one filter type should still apply the remaining filter to the elements
				}
			}

			if(filterCategoryType.length > 0 || filterCategoryResort.length > 0){
				$('.experiences__grid-item').each(function () {
					var shouldShowType = false;
					var shouldShowresort = false;
					var expFiltersCat = $(this).data('experiences-filter-categories').split('|');
					var resExpFiltersCat = $(this).data('experiences-resort-filter-categories').split('|');

					for ( var i = 0; i < filterCategoryType.length; i++ ) {
						if ( expFiltersCat.indexOf(filterCategoryType[i]) > -1) {
							shouldShowType = true;
						}
					}

					for ( var i = 0; i < filterCategoryResort.length; i++ ) {
						if ( resExpFiltersCat.indexOf(filterCategoryResort[i]) > -1) {
							shouldShowresort = true;
						}
					}
					if(!shouldShowType && !shouldShowresort){
						$(this).fadeOut();
					}else{
						$(this).fadeIn();
					}
				});
			}else{
				$('.experiences__grid-item').fadeIn();
			}

			// Update the mobile dropdown
			if (($(window).innerWidth() > 640) && (hasfilterDropdown)) {
				$_filterElement.find('.experiences-filter-controls-dropdown select').val($(this).data('experiences-filter-category'));
			}
		});

		if ( $(window).width() <= 640 ) {
			$('.set-filters').click(function() {
			// Only show the items that match the filter
				allFilters.each(function () {
					$(this).fadeOut();

					if (typeof $(this).data('experiences-filter-categories') || $(this).data('experiences-resort-filter-categories') !== 'undefined') {
						var i;
						for ( i = 0; i < filterCategoryType.length; ++i ) {
							if ( $(this).data('experiences-filter-categories').indexOf(filterCategoryType[i]) > -1) {
								$(this).fadeIn();
							}
							if ( $(this).data('experiences-resort-filter-categories').indexOf(filterCategoryType[i]) > -1) {
								$(this).fadeIn();
							}
						}

						for ( i = 0; i < filterCategoryResort.length; ++i ) {
							if ( $(this).data('experiences-filter-categories').indexOf(filterCategoryResort[i]) > -1) {
								$(this).fadeIn();
							}
							if ( $(this).data('experiences-resort-filter-categories').indexOf(filterCategoryResort[i]) > -1) {
								$(this).fadeIn();
							}
						}
					}
				});
			});
		}		


		// Step 5: Set up the events for the dropdown menu
		if (hasfilterDropdown) {
			$_filterElement.find('.experiences-filter-controls-dropdown select').on('change', function () {
				if ($(window).innerWidth() <= 640) {
					if ( $(this).val() === 'all' ) {
						$_filterElement.find( '.' + $_filterElement.data('experiences-filter-controls') ).find('button.all').trigger('click');
					} else {
						$_filterElement.find( '.' + $_filterElement.data('experiences-filter-controls') ).find('button[data-experiences-filter-category="' + $(this).val() + '"]').trigger('click');
					}
				}
			});
		}
	});
});


// Filter a list to only unique values
// https://stackoverflow.com/questions/12551635/jquery-remove-duplicates-from-an-array-of-strings
function unique(list) {
	var result = [];
	$.each(list, function(i, e) {
		if ($.inArray(e, result) == -1) result.push(e);
	});
	return result;
}